<template>
  <div class="home">
    <div class="banner">
      <img src="../../assets/topHeader.png" alt="" class="bannerImg">
    </div>
    <van-sticky :offset-top="0">
      <div class="sticky">
        <div class="tab" :class="active === index ? 'active' : ''" v-for="(item, index) in tabList" :key="index" @click="handleTab(index)">{{ item }}</div>
      </div>
    </van-sticky>
    <!-- <ul class="ulWrapper">
      <li v-for="(item, index) in list" :key="index" class="listWrapper" @click="goPayOrder(item.id)">
        <div class="list-item">
          <img :src="item.imageUrl" alt="" class="listImg">
          <p class="listTitle">{{ item.goodsName }}</p>
          <p class="listTitleRight">月销：999999</p>
        </div>
      </li>
    </ul> -->
    <!-- 列表 -->
    <van-list
      v-model="loading"
      :finished="finished"
      offset="50"
      finished-text="没有更多了"
      class="listWrapper"
    >
      <div class="listContentWrapper">
        <van-cell v-for="(item, index) in list" :key="index" class="listContent">
          <img :src="item.imageUrl" alt="" class="goodsImg">
          <div class="goodsMessageWrapper">
            <div>
              <div class="title">{{ item.productName }}</div>
              <div class="content">{{ item.description }}</div>
            </div>
            <div class="operation">
              <span class="price">售价： {{ parseFloat(item.price / 100).toFixed(2) }}元</span>
              <div type="info" class="placeBtn" size="small" @click="goPayOrder(item.pageId)">
                立即办理<van-icon name="arrow" />
              </div>
            </div>
          </div>
        </van-cell>
      </div>
    </van-list>
    <ul class="useList">
      <li class="useImgWrapper" @click="refresh">
        <img src="../../assets/homeLogo.png" alt="" class="useImg">
        <span class="useTitle">首页</span>
      </li>
      <li class="useImgWrapper" @click="goLink('order')">
        <img src="../../assets/orderLogo.png" alt="" class="useImg">
        <span class="useTitle">订单查询</span>
      </li>
      <li class="useImgWrapper" @click="gokf()">
        <img src="../../assets/kfLogo.png" alt="" class="useImg">
        <span class="useTitle">联系客服</span>
      </li>
      <li class="useImgWrapper" @click="goLink('report')">
        <img src="../../assets/complaintLogo.png" alt="" class="useImg">
        <span class="useTitle">投诉</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { getHomeList, getTitle } from '../../api/api'

export default {
  name: 'home',
  props: {
    id: {
      require: true
    }
  },
  data () {
    return {
      list: [],
      loading: false,
      finished: false, //是否有更多
      page: 1,
      pageSize: 10,
      // count: null,
      tabList: ['中国移动', '中国联通', '中国电信', '中国广电'],
      active: null, //tab切换
      shopListData: [], // 商品总数据
    }
  },
  created () {
    this.$store.commit('setUserId', this.$route.params.id)
    this.$store.commit('setUrl', window.location.href.split('/home')[0])
    this._getHomeData()
    this._getTitle()
    this.active = null;
  },
  methods: {
    // tab切换
    handleTab(index){
      this.active = index;
      this.list = this.shopListData.filter(item => item.operator == this.active + 1);
      window.scrollTo({
        top:0,
        behavior:'smooth'
      })
    },
    // 查询列表
    _getHomeData () {
      this.loading = true
      getHomeList(this.$route.params.id).then(res => {
        if (res && res.code === 200) {
          // this.count = res.result.total
          if (res.code === 200) {
            this.shopListData = this.list = res.result;
            this.loading = false;
            this.finished = true
          }
        } else {
          this.loading = false
          this.finished = true
        }
      })
    },
    // 查询标题
    _getTitle () {
      getTitle(this.$route.params.id).then(res => {
        console.log(res)
        if (res.code === 200) {
          window.document.title = res.result.pageTitle
        }
      })
    },
    refresh () {
      this.active = null;
      this.list = []
      // this.count = null
      this.page = 1
      this.finished = false
      this._getHomeData()
    },
    // onLoad() {
    //   // 异步更新数据
    //   console.log('进来了')
    //   setTimeout(() => {
    //     if (this.list.length >= this.count) {
    //       this.finished = true
    //     } else {
    //       this.finished = false
    //       this.page++
    //       this._getHomeData()
    //     }
    //   }, 1000)
    // },
    // 跳转商品页
    goPayOrder (id) {
      this.$router.push({
        path: `/placeAnOrder/index/${id}`,
      })
    },
    goLink(type) {
      let url = new Map([
        ['order', '/order'],
        ['report', '/report']
      ])
      this.$router.push({
        path: url.get(type),
      })
    },
    gokf () {
      window.location.href = 'https://im-c.zmyiot.com/?appid=5a0762d9'
    }
  }
}
</script>

<style lang="less" scoped>
  .home {
    min-height: 100%;
    background-color: #F7F6F2;
    margin-bottom: 50px;
    .banner {
      height: 173px;
      margin: 0;
      padding: 0;
      .bannerImg {
        width: 100%;
        height: 173px;
      }
    }
    .sticky{
      margin: 8px;
      border-radius: 6px;
      background-color: #FFF;
      display: flex;
      justify-content: space-around;
      align-items: center;
      .tab{
        font-weight: bold;
        padding: 10px 0;
        border-top: 3px solid #FFF;
        border-bottom: 3px solid #FFF;
      }
      .active{
        color: #317ecc;
        border-bottom: 3px solid #317ecc;
      }
    }
    .listWrapper{
      padding-bottom: 76px;
      .listContentWrapper {
        margin: 8px 8px 0;
        .listContent {
          border-radius: 6px;
          margin-bottom: 10px;
          background-color: #fff;
          .goodsImg {
            width: 119px;
            height: 119px;
            border-radius: 6px;
          }
          .goodsMessageWrapper {
            margin-left: 6px;
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            .title {
              line-height: 17px;
              font-size: 15px;
              font-weight: bold;
              color: #333;
              text-align: justify;
            }
            .content {
              color: #666;
              font-weight: 500;
              font-size: 12px;
              line-height: 14px;
              margin: 5px 0;
              display: -webkit-box;
              overflow: hidden;
              word-break: break-all; /* break-all(允许在单词内换行。) */
              text-overflow: ellipsis; /* 超出部分省略号 */
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2; /** 显示的行数 **/
            }
            .operation {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .price {
                color: #F23A54;
                font-weight: bold;
                font-size: 13px;
              }
              .placeBtn {
                font-size: 12px;
                font-weight: bold;
                color: #fff;
                border-radius: 6px;
                padding: 2px 8px;
                background-color: #398afd;
              }
            }
          }
        }
        .listContent:last-child{
          margin-bottom: 0;
        }
      }
    }
    // 功能列表logo
    .useList {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      display: flex;
      background-color: #fff;
      .useImgWrapper {
        height: 76px;
        display: flex;
        flex: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .useImg{
          width: 39px;
          height: 39px;
        }
        .useTitle {
          font-size: 12px;
          margin-top: 10px;
        }
      }
    }
  }
  /deep/ .van-cell{
    padding: 6px;
  }
  /deep/ .van-cell__value {
    display: flex;
    justify-content: space-between;
  }
</style>